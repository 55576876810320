<template>
    <div class="d-flex">
        <section class="w-50 pe-4">
            <h1 class="mb-2">{{$t('navigation.franchises')}}</h1>
            <FormItem type="text" v-model="item.name" :label="$t('overview.name')" :required="true" />
            <FormItem type="select" v-model="item.hospitality_group_id" :options="hospitalityGroups" :label="$t('navigation.holdings')" placeholder="-" />
            <FormItem type="select" v-model="item.dealer_id" :options="dealers" :label="$t('navigation.dealers')" :required="true" :disabled="isLoggedInAsDealer" />
            <LogoUpload :item="item" margin="my-1" />
        </section>
    </div>
    <FooterButtons @prev="prev" @save="save" :disabled="isSaving" />
</template>

<script>
    import franchiseService from '@/services/FranchiseService';
    import hospitalityGroupService from '@/services/HospitalityGroupService'
    import FooterButtons from '@/components/FooterButtons.vue'
    import LogoUpload from '@/components/LogoUpload.vue'
    import dealerService from '@/services/DealerService';
    import store from '@/store/user';
    export default {
        name: 'FranchiseDetail',
        components: {
            FooterButtons,
            LogoUpload
        },
        data() {
            return {
                item: {
                    "hospitality_group_id": null,
                    "name": "",
                    "icon_url": null,
                    "icon_image_data": null,
                    "dealer_id": null
                },
                dealers: [],
                franchises: [],
                hospitalityGroups: [],
                isLoggedInAsDealer: false,
                isNew: true,
                isSaving: false
            }
        },
        methods: {
            validate(){
                //Validation
                const els = document.querySelectorAll("section input[type='text']:not(.optional), section select:not(.optional)");
                let valid = true;
                els.forEach(el => {
                    if(!el.value){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => {
                                thisEl.target.classList.remove("is-invalid");
                            })
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                })
                return valid;
            },
            prev(){
                this.$router.push({'name': "Ketens"});
            },
            save() {
                if(!this.validate()){
                    return;
                }
                this.isSaving = true;
                franchiseService.updateOrStore(this.item).then(response => {
                    this.$router.push({'name': "Ketens"});
                }).catch(e => {
                    this.isSaving = false;
                    this.toastError(e);
                });
            },
            setFileInfo(evt){
                var files = evt.target.files || evt.dataTransfer.files;
                if (!files.length)  return;

                var file = files[0];
                var reader  = new FileReader();
                reader.onload = (e) => {
                    const imgBase64Path = e.target.result;
                    this.item.icon_image_data = imgBase64Path;
                }

                if (file) reader.readAsDataURL(file);
            },
            deleteLogo(){
                if(confirm(this.$t('form.are_you_sure_delete_logo'))){
                    this.item.icon_url = null;
                    this.item.icon_image_data = null;
                }
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            this.isLoggedInAsDealer = store.getters.getUserType == 3;
            if(!this.isNew){
                franchiseService.show(locationId).then(response => {
                    this.item = response.data;
                    if(this.isLoggedInAsDealer) this.item.dealer_id = store.getters.getUser.dealer_id;
                });
            }
            else if(this.isLoggedInAsDealer) this.item.dealer_id = store.getters.getUser.dealer_id;
            else this.item.dealer_id = 1;
            hospitalityGroupService.index().then(response => this.hospitalityGroups = response.data);
            dealerService.index().then(response => this.dealers = response.data);
        }
    }

</script>